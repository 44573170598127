import { render, staticRenderFns } from "./BrandsSort.vue?vue&type=template&id=6f7f9894&"
import script from "./BrandsSort.vue?vue&type=script&lang=js&"
export * from "./BrandsSort.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports