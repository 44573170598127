<template>
  <b-overlay
    variant="white"
    :show="showLoading"
    spinner-variant="primary"
    blur="0"
    opacity=".75"
    rounded="sm"
  >
    <div
      v-if="brands.length"
      class="accordion"
      role="tablist"
    >
      <draggable
        animation="200"
        ghost-class="ghost-sort"
        :list="brands"
        @change="changedSorting"
      >
        <div
          v-for="brand in brands"
          :key="brand.slug"
          class="accordion__item "
        >
          <div class="accordion-label accordion-label--disable-arrow">
            <div class="accordion-label__content">
              <div class="row w-100 align-items-center">
                <div class="col-12 col-lg-4">
                  <div class="d-flex align-items-center">
                    <feather-icon
                      icon="MenuIcon"
                      class="mr-2"
                    />
                    <div class="accordion-label__icon">
                      <img
                        v-if="brand.logo_sm"
                        :src="brand.logo_sm.url"
                        alt="логотип"
                      >
                      <div
                        v-else
                        class="accordion-label__icon-cover"
                      />
                    </div>
                    <span class="d-flex align-items-center">
                      {{ brand.name }}
                    </span>
                  </div>
                </div>
                <div class="col-12 col-lg-4">
                  {{ brand.name_rus || '—' }}
                </div>
                <div class="col-12 col-lg-4">
                  <code>{{ brand.slug }}</code>
                </div>
              </div>
            </div>
          </div>
        </div>
      </draggable>
    </div>
    <div v-else>
      Пока нет ни одной услуги
    </div>
  </b-overlay>
</template>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Draggable from 'vuedraggable'
import SiteBrand from '@/api/http/models/site/brand/SiteBrand'
import { BOverlay } from 'bootstrap-vue'
import { mapGetters } from 'vuex'

export default {
  components: {
    BOverlay,
    Draggable,
  },
  data() {
    return {
      showLoading: true,
      brands: [],
    }
  },
  computed: {
    ...mapGetters({
      currentSite: 'user/currentSite',
    }),
  },
  async created() {
    const response = await SiteBrand.getForSort(this.currentSite.slug)

    if (response.data) {
      this.brands = response.data
      this.showLoading = false
    }
  },
  methods: {
    async changedSorting() {
      const brands = this.brands.map((brand, index) => ({
        slug: brand.slug,
        order: index + 1,
      }))

      const response = await SiteBrand.sort(this.currentSite.slug, {
        brands,
      })

      if (response.status === 'success') {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Бренды отсортированы',
            icon: 'CheckIcon',
            variant: 'success',
          },
        })
      }
    },
  },
}
</script>
